<template>
  <form-modal v-bind="bindChain.form" v-on="onChain.form">
    <v-form :ref="formRef">
      <v-autocomplete
        v-model="form.id_empresa"
        :items="empresas"
        :loading="loadingEmpresas"
        item-text="empresa_descripcion"
        item-value="id"
        label="Empresa"
        :disabled="!!formData"
        placeholder="Seleccione una empresa..."
        :rules="[(v) => !!v || 'Debe seleccionar una empresa.']"
      ></v-autocomplete>

      <v-text-field
        v-model="form.nombre"
        @keyup="form.nombre = codeFormat(form.nombre, false)"
        name="nombre"
        label="Nombre"
        :disabled="!!formData"
        placeholder="Escriba el nombre del nuevo formato..."
        :rules="[(v) => !!v || 'El campo nombre es requerido.']"
      >
      </v-text-field>

      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-text-field
            class="mr-1"
            v-model="form.formato"
            name="formato"
            label="Formato"
            placeholder="Escriba el formato..."
            :rules="[(v) => !!v || 'El campo formato es requerido.']"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            class="ml-1"
            v-model="form.tipo_dato"
            name="tipoDato"
            :disabled="!!formData"
            label="Tipo de dato"
            placeholder="Escriba el tipo de dato..."
            :rules="[(v) => !!v || 'El campo tipo de dato es requerido.']"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </form-modal>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
import { mapActions } from "vuex";

export default {
  name: "FormatosModalForm",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de formatos",
      form: {
        nombre: "",
        formato: "",
        tipo_dato: "",
        id_empresa: undefined,
      },
      empresas: [],
      loadingEmpresas: false,
    };
  },

  methods: {
    ...mapActions("Integrator", ["getCompanies"]),

    setup() {
      this.loadingEmpresas = true;
      this.getCompanies()
        .catch((error) => {
          this.snackbar(error.info);
        })
        .then((response) => {
          this.empresas = response;
        })
        .finally(() => {
          this.loadingEmpresas = false;
        });
    },

    setter() {
      this.form.nombre = this.formData.nombre;
      this.form.formato = this.formData.formato;
      this.form.tipo_dato = this.formData.tipo_dato;
      this.form.id_empresa =
        this.formData.empresa?.id ?? this.formData.id_empresa;
    },
  },
};
</script>
