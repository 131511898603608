<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <table-top v-bind="bindChain.tableTop" v-on="onChain.tableTop" />
        </template>

        <template #item.actions="{ item }">
          <table-actions
            v-bind="bindChain.tableActions"
            v-on="onChain.tableActions(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <Form v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import PageMixin from "@/Mixins/SetPageMixin.js";
import Form from "@/components/Forms/integrations/formatos.vue";
import { mapActions } from "vuex";

export default {
  name: "FormatosIntegrationPage",
  mixins: [PageMixin],
  components: {
    Form,
  },
  data: () => ({
    headers: [
      { text: "Empresa", value: "empresa.descripcion", align: "left" },
      { text: "Nombre", value: "nombre", align: "left" },
      { text: "Formato", value: "formato", align: "left" },
      { text: "Tipo de dato", value: "tipo_dato", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    formatos: [],
    itemId: "id",
  }),
  computed: {
    items() {
      return this.formatos;
    },
  },
  methods: {
    ...mapActions("Integrator", [
      "getFormats",
      "CrearFormato",
      "deleteFormato",
      "UpdateFormato",
    ]),

    setup() {
      this.loading = true;
      this.getFormats()
        .then((response) => {
          this.formatos = response;
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => (this.loading = false));
    },
    saveMethod() {
      return this.CrearFormato;
    },
    deleteMethod() {
      return this.deleteFormato;
    },
    updateMethod() {
      return this.UpdateFormato;
    },
  },
};
</script>
